.DefaultHeaderTop0a772718E2874403999cEc13d8119d0c {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 7rem;
  padding: 1rem;
  position: relative;
}

.DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .linked-logo {
  display: flex;
  justify-content: center;
}

.DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .logo {
  width: 10rem;
}

.DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  /* color: #800080; */
  color: #147531;
}

.DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .Navigation:hover {
  color: #1C9941;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

.DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Show/hide navigation */

@media (min-width: 900px) {
  .DefaultHeaderTop0a772718E2874403999cEc13d8119d0c {
    padding: 2.25rem 1.75rem 1rem 1.75rem;
    height: 9rem;
  }

  .DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .linked-logo {
    flex-grow: 1;
  }
  .DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .logo {
    width: 12rem;
  }

  .DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .navigation {
    flex-grow: 4;
  }

  .DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .Navigation {
    display: flex;
  }

  .DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .Navigation {
    font-size: 120%;
  }
  .DefaultHeaderTop0a772718E2874403999cEc13d8119d0c .logo {
    width: 15.25rem;
  }
}
.DefaultFooter88dc320650b34052Bc34554e68c8f165 {
  /* background-color: #800080; */
  background-color: #105a26;
  color: white;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  font-family: Montserrat;
}

.DefaultFooter88dc320650b34052Bc34554e68c8f165 > * {
  text-align: center;
}

.DefaultFooter88dc320650b34052Bc34554e68c8f165 .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.DefaultFooter88dc320650b34052Bc34554e68c8f165 .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooter88dc320650b34052Bc34554e68c8f165 > .logo-wrapper {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DefaultFooter88dc320650b34052Bc34554e68c8f165 .LogoWrapper {
  font-size: 90%;
}

.DefaultFooter88dc320650b34052Bc34554e68c8f165 .LogoWrapper {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.DefaultFooter88dc320650b34052Bc34554e68c8f165 .LogoWrapper:hover {
    --tw-text-opacity: 1;
    color: rgb(0 194 152 / var(--tw-text-opacity));
}

.DefaultFooter88dc320650b34052Bc34554e68c8f165 .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooter88dc320650b34052Bc34554e68c8f165 .GroupCopyright {
  font-size: 90%;
}

.DefaultFooter88dc320650b34052Bc34554e68c8f165 .GroupFlowTag .PoweredBy {
  font-size: 90%;
}
.WelcomeBackPopUp {
  padding: 2rem;
  position: relative;
  background-color: white;
  box-shadow: 5px 5px 5px rgb(50 50 50 / 20%);
}


.WelcomeBackPopUp .CloseButton {
  color: black;
}

.WelcomeBackPopUp .BackgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: var(--background-image);
  opacity: 0.2;
  background-position: center;
  background-size: cover;
}

.WelcomeBackPopUp .Body {
  position: relative;
}

.WelcomeBackPopUp .Button {
  background-color: var(--accent-color);
  color: white;
  font-family: 'Bebas Neue';
  font-size: 120%;
  letter-spacing: 0.05rem;
  padding: 0.5rem 2rem;
  text-align: center;
}
.WelcomeBackPopUp .Button:hover {
  fliter: brightness(1.75);
}
.HomePageB597a32841ab446586d0C3622d531800 .PageQuote {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.HomePageB597a32841ab446586d0C3622d531800 .PageQuote > * {
  flex-grow: 1;
}

.HomePageB597a32841ab446586d0C3622d531800 .PageQuote .Image {
  object-fit: cover;
}

.HomePageB597a32841ab446586d0C3622d531800 .PageQuote .TextContent {
  background-color: #6950a8;
  color: white;
  padding: 3rem;
}

.HomePageB597a32841ab446586d0C3622d531800 .PageQuote .Title {
  font-family: "Bebas Neue";
  font-size: 350%;
  line-height: 100%;
}

.HomePageB597a32841ab446586d0C3622d531800 .PageQuote .Text {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 125%;
}

@media (min-width: 900px) {
  .HomePageB597a32841ab446586d0C3622d531800 .PageQuote > * {
    width: 50%;
    height: 26rem;
  }
}

@media (min-width: 1200px) {
  .HomePageB597a32841ab446586d0C3622d531800 .PageQuote .Title {
    font-size: 400%;
    line-height: 125%;
  }
}
.MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .MarketingCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

.MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .MarketingCard .ImageWrapper img {
  max-width: 400px;
}

@media (min-width: 768px) {
  .MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .MarketingCard {
    gap: 6rem;
  }
}

.MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .MarketingCard .ImageOverlay {
  opacity: 0.3;
  background-color: #fbe4d5;
}

@media (min-width: 768px) {
  .MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .CardTextContent {
    width: 25rem;
  }
}

.MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .CardHeading {
  color: var(--accent-color);
  font-family: Bebas Neue;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .CardHeading {
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }
}

.MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .CardText {
  font-family: Montserrat;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

@media (min-width: 768px) {
  .MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .ButtonWrapper {
    justify-content: flex-start;
  }
}

.MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue'; 
  font-weight: 700; 
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
.MarketingBody3469e5da49df403288e597389c4dc504 {
  padding: 2em;
  /* background-color: #d9ead3; */
  background-color: #ebf1e8;
}
.HomeHeaderC0f27cf75b2b456fA4dc6c7868c94eab {
  background-color: white;
  position: relative;
  color: #030C22;
}

.HomeHeaderC0f27cf75b2b456fA4dc6c7868c94eab .background-image {
  background-image: var(--background-image);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.4;
}

.HomeHeaderC0f27cf75b2b456fA4dc6c7868c94eab .header-top-wrapper {
  position: relative;
  height: 6rem;
}

.HomeHeaderC0f27cf75b2b456fA4dc6c7868c94eab .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: white;
}

@media (min-width: 900px) {
  .HomeHeaderC0f27cf75b2b456fA4dc6c7868c94eab .header-top-wrapper {
    height: 9.25rem;
  }
}
.SecondaryPageC61cebd4A4f949e29e2aEc1da9dda723 .text-content {
  padding: 0 1rem;
}
.hero .title{
  color: red;
}
.PageQuoteF1e3aa368df148bc845b24932f7e8ac6 {
  display: flex;
  flex-wrap: wrap;
}
.SecondaryNav744b01bc4c8f44a2Bf3519c285e7b085 {
  padding: 1rem 0;
}
.DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917 {
  content: var(--logo);
}
.MessagePages967bd43dF2bd4215Af729057a6d8f74d {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.ShortHeader93970b6e1912477098cb56eb7522ef50 {
  background-color: white;
  color: #030C22;
  position: relative;
}

.ShortHeader93970b6e1912477098cb56eb7522ef50 .background-image {
  background-image: var(--background-image);
  background-size: cover;
  background-position: center; /* Center the background image */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.1;
}


.ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: relative;
}

.ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .linked-logo {
  display: flex;
  justify-content: center;
}

.ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .logo {
  width: 7rem;
}

.ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #500064;
}

.ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

/* Larger screens */

@media (min-width: 900px) {
  .ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .linked-logo {
    flex-grow: 1;
  }
  .ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .logo {
    width: 7rem;
  }

  .ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .navigation {
    flex-grow: 4;
  }

  .ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .Navigation {
    display: flex;
  }

  .ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top {
    padding: 0 1.75rem;
    height: 5rem;
  }

  .ShortHeader93970b6e1912477098cb56eb7522ef50 .header-top .logo {
    width: 10rem;
  }
}
.AboutLevelingPageD6e25bda2bb647e2Ad606e132933daae .Button {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgb(120, 0, 80);
  letter-spacing: 0.1rem;
}
.SecondaryMarketingHero918e1340Fc8a4212895dAe86e46718e9 {
  align-items: center;
  color: #030C22;
  display: flex;
  flex-grow: 1;
  padding-left: 5rem;
}

.SecondaryMarketingHero918e1340Fc8a4212895dAe86e46718e9 .title {
  color: #500064;
  font-family: 'Bebas Neue';
  font-size: 300%;
  font-weight: 500;
  line-height: 100%;
}

@media (min-width: 640px) {
  .SecondaryMarketingHero918e1340Fc8a4212895dAe86e46718e9 .title {
    font-size: 400%;
  }
}

.SecondaryMarketingHero918e1340Fc8a4212895dAe86e46718e9 .button > a {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 110%;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ed2e38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.SecondaryMarketingHero918e1340Fc8a4212895dAe86e46718e9 .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a {
  height: 18rem;
  position: relative;
  color: #030C22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a .background-image {
  background-image: var(--background-image);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10;
  opacity: 0.5;
}

.SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a .header-top-wrapper {
  position: relative;
}

.SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: white;
  z-index: 0;
}

.SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a .header-top {
  z-index: 1;
  position: relative;
}
.HomePageHero3583b7061f7444e483edB61f66eb0c1e {
  color: #030C22;
  min-height: 35rem;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

@media (min-width: 768px) {
  .HomePageHero3583b7061f7444e483edB61f66eb0c1e {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.HomePageHero3583b7061f7444e483edB61f66eb0c1e .title {
  color: black;
  font-family: 'Bebas Neue';
  font-size: 300%;
  font-weight: 500;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.HomePageHero3583b7061f7444e483edB61f66eb0c1e .button {
  display: flex;
  justify-content: center;
}

.HomePageHero3583b7061f7444e483edB61f66eb0c1e .button > a {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: 'Bebas Neue';
  font-weight: 600;
  font-size: 150%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.75rem 3rem;
  background-color: var(--accent-color);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.HomePageHero3583b7061f7444e483edB61f66eb0c1e .button > a:hover {
  --tw-brightness: brightness(1.2);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 900px) {
  .HomePageHero3583b7061f7444e483edB61f66eb0c1e .title {
    margin-top: 2.2rem;
    text-align: left;
  }

  .HomePageHero3583b7061f7444e483edB61f66eb0c1e .button {
    display: block;
    justify-content: start;
  }
}